import { RouteRecordRaw } from "vue-router"

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    props: (route) => ({
      query: route.query,
    }),
    component: () => import("layouts/MainLayout.vue"),
    children: [{ path: "", component: () => import("pages/Index.vue") }],
  },
  {
    path: "/register/:bookId",
    props: (route) => ({
      query: route.query,
    }),
    component: () => import("layouts/MainLayout.vue"),
    children: [{ path: "", props: true, component: () => import("pages/Onboard.vue") }],
  },
  {
    path: "/registered",
    props: (route) => ({
      query: route.query,
    }),
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/Registered.vue"),
      },
    ],
  },
  {
    path: "/dashboard/:entityId",
    props: (route) => ({
      query: route.query,
    }),
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        props: true,
        component: () => import("pages/Dashboard.vue"),
      },
    ],
  },
  {
    path: "/history/:folder/:entityId",
    props: (route) => ({
      query: route.query,
    }),
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        props: true,
        component: () => import("pages/History.vue"),
      },
    ],
  },
  {
    path: "/clients/manage/:entityId",
    props: (route) => ({
      query: route.query,
    }),
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        props: true,
        component: () => import("pages/ManageClients.vue"),
      },
    ],
  },
  {
    path: "/clients/invite/:entityId",
    props: (route) => ({
      query: route.query,
    }),
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        props: true,
        component: () => import("pages/InviteClients.vue"),
      },
    ],
  },
  {
    path: "/suppliers/manage/:entityId",
    props: (route) => ({
      query: route.query,
    }),
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        props: true,
        component: () => import("pages/ManageSuppliers.vue"),
      },
    ],
  },
  {
    path: "/suppliers/invite/:entityId",
    props: (route) => ({
      query: route.query,
    }),
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        props: true,
        component: () => import("pages/InviteSuppliers.vue"),
      },
    ],
  },
  {
    path: "/entity/:entityId",
    props: (route) => ({
      query: route.query,
    }),
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        props: true,
        component: () => import("pages/Entity.vue"),
      },
    ],
  },
  {
    path: "/user/:userId",
    props: (route) => ({
      query: route.query,
    }),
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        props: true,
        component: () => import("pages/MyDetails.vue"),
      },
    ],
  },
  {
    path: "/signup",
    component: () => import("layouts/FullscreenLayout.vue"),
    children: [
      {
        path: "",
        props: (route) => ({
          product: route.query.product,
        }),
        component: () => import("pages/Signup.vue"),
      },
    ],
  },
  {
    path: "/auth",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        props: (route) => ({
          token: route.query.token,
          error: route.query.error,
          state: route.query.state,
          logout: route.query.logout,
        }),
        component: () => import("pages/Auth.vue"),
      },
    ],
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: "/:catchAll(.*)*",
    component: () => import("pages/Error404.vue"),
  },
]

export default routes
