
import { defineComponent } from "vue"
import { app } from "@/env/app"

export default defineComponent({
  name: "App",
  props: {
    // These are just to show in Vue Tools browser extension
    app: {
      type: Object,
      default: () => {
        return {
          name: app.name,
          version: app.version,
        }
      },
    },
    logging: {
      type: String,
      default: app.logging,
    },
  },
})
